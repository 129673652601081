<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <!-- <span v-my-loading>设备清单</span> -->
        <span>设备清单</span>
        <el-button @click="add" class="btn" type="primary" size="mini"
          :disabled="roleId == 2 || roleId == 3 || roleId == 4">新增设备</el-button>
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="位置">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="请选择位置">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类">
            <el-select size="small" v-model="formInline.deviceTypeId" clearable placeholder="请选择分类">
              <el-option
                v-for="item in leixin"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门">
            <el-select size="small" v-model="formInline.departmentId" clearable placeholder="请选择所属部门">
              <el-option
                v-for="item in bumen"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人">
            <el-select size="small" v-model="formInline.staffId" clearable placeholder="请选择负责人">
              <el-option
                v-for="item in yuangong"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="materialsCode" label="物料编码"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="specification" label="规格参数"></el-table-column>
          <el-table-column prop="deviceTypeName" label="类型"></el-table-column>
          <el-table-column prop="departmentName" label="所属部门"></el-table-column>
          <el-table-column prop="staffName" label="负责人"></el-table-column>
          <el-table-column prop="count" label="设备总数"></el-table-column>
          <el-table-column prop="useCount" label="使用数量"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                  <!-- <el-dropdown-item><div @click="scrap(scope.row)">报废</div></el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      isChangeOpen: 0,
      isDetailOpen: 0,
      isEditOpen: 0,
      sheshi: [],
      bumen: [],
      leixin: [],
      yuangong: [],
      detailRow: {},
      editRow: {},
      roleId: 0
    };
  },
  methods: {
    scrap(row) {
      this.$confirm('确认报废?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceStatus', {
          id: row.id,
          statusId: 4
        }).then(res => {
          this.loadListData()
        })
      })
    },
    edit(row) {
      this.$router.push({ name: 'deviceListEdit', params: {
        row: row,
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    details(row) {
      this.$router.push({ name: 'deviceListDetail', params: {row: row}})
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceDelete', {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    add() {
      this.$router.push({ name: 'deviceListAdd', params: {
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadListData()
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('deviceChecklist', {
        departmentId: this.formInline.departmentId?this.formInline.departmentId:null,
        deviceTypeId: this.formInline.deviceTypeId?this.formInline.deviceTypeId:null,
        staffId: this.formInline.staffId?this.formInline.staffId:null,
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    load_sheshi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.sheshi = res.data;
      });
    },
    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.leixin = res.data;
      });
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.load_sheshi();
    this.load_bumen();
    this.load_leixing();
    this.load_yuangong();
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>